import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Container from 'react-bootstrap/Container'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MainBannerArea from '../components/Index/MainScreen/Desktop/MainBannerArea'
import PaymentPartners from '../components/Index/PaymentPartners/PaymentPartners'
import { HOME_PAGE } from '../components/download-links'

import './index.scss'
import './refer-and-earn.scss'
import InfoCard from '../components/Games/CategoryAndGamesInfo/InfoCard'
import Title from '../components/Title/Title'
import { REFER_EARN } from '../components/internal-links'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Refer And Earn',
    url: REFER_EARN,
  },
]

const GamesList = loadable(() =>
  pMinDelay(import('../components/Index/GamesList/GamesList'), 200)
)

const ReferEarn: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      homeMobBanner: file(
        relativePath: { eq: "carousel-home/mobile/banner4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      homeBanner: file(
        relativePath: { eq: "carousel-home/desktop/banner4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      bg4: file(relativePath: { eq: "carousel-home/4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const homeBanner = [
    {
      id: 1,
      mob: data.homeMobBanner.childImageSharp.gatsbyImageData,
      desk: data.homeBanner.childImageSharp.gatsbyImageData,
      background: '#000000',
    },
  ]

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={HOME_PAGE}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Refer &amp; Earn Up to ₹10,000 Everyday on Mega | Refer &amp; Earn. Share. Get Paid. Repeat!"
        description="Play Poker & Rummy to win money! Download Mega to play Carrom, Rummy, Poker, Pool &amp; more. Instant withdrawals. Verified profiles. Play now!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
      <div className="refer-earn-page">
        <MainBannerArea
          multipleBanner={homeBanner}
          customClass="home-banner"
          pageName="refer"
        />
        <Container>
          <InfoCard customClass="refer-infocard">
            <h2 className="page-title">Refer &amp; Earn upto ₹25000!</h2>
            <div className="infocard-content">
              <ul>
                <li>
                  <h4>Invite a friend to win ₹50</h4>
                  <p>
                    You get ₹50 bonus cash when your friend signs up using your
                    referral link
                  </p>
                </li>
                <li>
                  <h4>Win ₹100 when they deposit</h4>
                  <p>
                    When your friend deposits money for the first time you get
                    ₹100 bonus cash
                  </p>
                </li>
                <li>
                  <h4>Play a game together to win ₹100</h4>
                  <p>
                    When you and your friend play a game together, you get ₹100
                    bonus cash
                  </p>
                </li>
              </ul>
            </div>
          </InfoCard>
          <div className="refer-content-sec">
            <Title>
              <div>
                What do you{' '}
                <span className="underline">and your friend get?</span>
              </div>
            </Title>
            <div className="refer-content-block">
              <h4>What do you get?</h4>
              <ul>
                <li>
                  When your friend signs up using your referral link you get ₹50
                  bonus cash
                </li>
                <li>
                  When your friend deposits for the first time, you get ₹100
                  bonus cash
                </li>
                <li>
                  When you and your friend play a game together, you get ₹100
                  bonus cash
                </li>
                <li>You can earn upto ₹25,000 bonus cash through referrals</li>
              </ul>
            </div>
            <div className="refer-content-block">
              <h4>What do your friends get?</h4>
              <ul>
                <li>
                  Your friend gets ₹50 when they sign up using your referral
                  link
                </li>
                <li>
                  When your friend deposits for the first time, they get ₹100
                  bonus cash
                </li>
                <li>
                  When you and your friend play a game together, they get ₹100
                  bonus cash
                </li>
              </ul>
            </div>
            <div className="refer-content-block">
              <h4>How to generate referral link?</h4>
              <ul>
                <li>
                  Download Mega app on clicking on the{' '}
                  <strong>&quot;Download now button&quot;</strong> or{' '}
                  <strong>&quot;SMS App Link&quot;</strong> present on this page
                </li>
                <li>
                  After the successful installation of application, enter the
                  credentials as asked
                </li>
                <li>
                  Further, in the hamburger menu you will see a tab &quot;
                  <strong>Refer &amp; Earn</strong>&quot;
                </li>
                <li>
                  From there, click on &quot;<strong>Refer</strong>&quot; and
                  you will receive a link which you will forward to people whom
                  you want to refer
                </li>
                <li>
                  You will receive the notifications &amp; bonus cash as and
                  when your referee signs up and makes a deposit
                </li>
              </ul>
            </div>
            <div className="refer-content-block">
              <h4>Maximize Your Withdrawable Earnings By Referring Friends!</h4>
              <ul>
                <li>
                  There are no limits on the number of friends you can invite
                  and for each friend you invite you earn upto ₹250
                </li>
                <li>
                  Invite maximum number of people to play on Mega App to
                  maximize your earnings
                </li>
                <li>
                  After inviting, play a game with them and welcome them on the
                  platform
                </li>
                <li>
                  In return, get a chance to earn upto ₹25,000 through referral
                  program
                </li>
              </ul>
            </div>
          </div>
        </Container>
        <GamesList
          pokerLink={pokerOneLinksStaticPage}
          rummyLink={rummyOneLinksStaticPage}
        />
        <PaymentPartners
          title={
            <>
              <div>
                Withdraw your winnings in less than{' '}
                <span className="secs">60 seconds</span>
              </div>
            </>
          }
        />
      </div>
    </Layout>
  )
}

export default ReferEarn
