import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import './Feature.scss'

export interface FeatureInterface {
  src: IGatsbyImageData
  title: string
  titleHindi?: string
  titleColor: string
  text?: string
  textHindi?: string
  width: string
  lists?: string[]
  listsHindi?: string[]
}

interface Props {
  feature: FeatureInterface
  lang?: string
}

const Feature: React.FC<Props> = ({ feature, lang }) => {
  return (
    <div className="feature">
      <div className="feature-image">
        <GatsbyImage
          image={feature.src}
          alt={
            lang === 'hindi'
              ? feature.titleHindi || feature.title
              : feature.title
          }
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <div className="feature-texts">
        <div className="feature-texts-title">
          {lang === 'hindi' ? feature.titleHindi : feature.title}
        </div>
        {feature.lists && (
          <ul className="feature-lists">
            {lang === 'hindi'
              ? feature.listsHindi?.map(e => {
                  return <li key={e}>{e}</li>
                })
              : feature.lists.map(e => {
                  return <li key={e}>{e}</li>
                })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Feature
